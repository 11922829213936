@import "../../../../../styles/variables.scss";

.dialogBox {
  background-color: theme-color(background-color-default);
  border-radius: 12px;
  border: none;
  width: 1140px;
  padding: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1205px) {
  .dialogBox {
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.addTriviaDialogBox {
  background-color: theme-color(background-color-default);
  border-radius: 12px;
  border: none;
  width: 750px;
  padding: 24px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1205px) {
  .addTriviaDialogBox {
    width: calc(100% - 40px);
    box-sizing: border-box;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.closeButton {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: theme-color(background-color);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  height: 47px;
  width: "100%";
  padding-left: 22px;
  margin-bottom: 20px;
  background-color: theme-color(primary);
}
